@import '../../config';

.featuredpdt_home {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

#homefeatured.sm {
  width: 100%;
  max-width: 1141px;
  margin: 0 auto;

  .slick-slide {
    padding: 0 8px !important;
  }

  .window {
    background: white;
  }

  .product_img_link {
    position: relative;
    display: block;

    img {
      width: 100%;
      height: auto;
    }
  }

  .product-image-container {
    &:hover {
      border-bottom: none;
    }

    .content_price {
      display: none;
    }
  }

  .content_price {
    margin-bottom: 0 !important;
  }

  .carousel {
    display: flex;
    align-items: stretch;
    height: 100%;

    .slide {
      display: block;
      flex-direction: initial;
      justify-content: initial;
      float: none;
      width: auto;
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .wishlist {
    @apply text-black;
    right: 20px !important;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &::before {
      content: "\f08a";
      display: inline-block;
      width: 30px;
      height: 30px;
      padding: 5px;
      font-family: "FontAwesome";
      border: #1d1b1b 1px solid;
    }

    &:hover {
      &::before {
        content: "\f004";
      }
    }
  }
  
  .livraison {
    position: absolute;
    bottom: 0px;
    left: 105px;

    span {
      width: auto;
      max-width: 110px;
      margin-left: 0;
      padding-right: 10px;
      padding-left: 10px;
    }

    @screen s-md {
      left: 120px;
    }
  }


  @screen l-md {
    .flexslider {
      pointer-events: none;
      opacity: 0;
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 30%;
  width: auto;
  height: 40px;
  margin-top: -10px;
  overflow: inherit;
  font-size: 0;
  line-height: 0;
  text-align: center;
  text-decoration: none;
  border: none;
  outline: none;
  transition: 0.2s transform $out-cubic;
}

.slick-next {
  right: -30px;
  padding-right: 17px;
  padding-left: 0;
  background: url(#{$img-dir}fleche-right.png) no-repeat 0% 0%;

  &:hover {
    transform: translateX(2px);
  }
}

.slick-prev {
  left: -30px;
  padding-right: 17px;
  background: url(#{$img-dir}fleche-left.png) no-repeat 0% 0%;

  &:hover {
    transform: translateX(-2px);
  }
}
